import React from "react";
import { setShop, getPlatformShop, deleteShop } from "../http/api.js";
import CommonUtils from '../http/Common.js';
import { Space, Divider, Button, Checkbox, Form, Input, Modal, Table, Tag, message, Card, Tooltip, Col, Row, Typography, Spin } from 'antd';
import SearchItem from "./SearchItem.js";
import { DownloadOutlined } from '@ant-design/icons';
import WechatJsCommon from './components/WechatJsCommon.js';


class ShopAuthTiktok extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      spinning: false,//是否为加载中状态
    };
  }

  componentDidMount() {
    let _this = this;
    CommonUtils.goToTop()//回到顶部
    WechatJsCommon.wechatJsSignFun()//给微信的浏览器中签名，以便转发的时候使用到 

    this.searchFun({})//查询店铺数据

  }

  setStateKeyValue = (key, value) => {
    this.setState({
      [key]: value
    })
  }


  //添加授权店铺
  addAuthShopFun = (shop_data) => {
    let _this = this;
    _this.setState({
      isModalOpen_addShop: false,
    })
    shop_data["region"] = "RUS"
    shop_data["platform"] = "tiktok"
    // "attr2": total_limit, # 店铺一共可以创建多少商品
    // "attr3": total_usage, # 已经创建了多少商品

    console.log("shop_data", shop_data)

    setShop([shop_data]).then(res => {
      if (res && res.data && res.data.success) {
        let resData = res.data.data;
        this.searchFun({})//查询店铺数据
        message.info("创建成功！")
      } else {
        message.info("创建失败，请重试！")
      }
    }).catch(function (error) {//请求失败时
      message.info("创建失败，请重试！注意店铺名称不能有特殊标点符号！")
    })
  }

  //取消授权店铺
  deleteShopFun = (shopId) => {
    let _this = this
    deleteShop({ "shopId": shopId }).then(res => {
      if (res && res.data && res.data.success) {
        let resData = res.data.data;
        this.searchFun({})//查询店铺数据
        message.info("取消成功！")
      } else {
        message.info("请求失败，请重试！")
      }
    })
  }

  //查询_分页
  searchFun_Page = (current, pageSize) => {
    var paramsParams = this.state.paramsParams
    paramsParams["current"] = current
    paramsParams["size"] = pageSize
    this.searchFun(paramsParams) //查询代理人拆分的代金券
  }


  searchFun = (params) => {
    let _this = this;
    params["platform"] = "tiktok"

    this.setState({
      spinning: true,
      paramsParams: params
    })

    getPlatformShop(params).then(res => {
      _this.setState({ spinning: false })
      if (res && res.data && res.data.success) {
        let resData = res.data.data;

        //console.log("resData===>", resData)

        var tableData = []
        resData.map(item => {
          tableData.push({
            key: item["id"],
            shopId: item["shopId"],
            shopName: item["shop_name"],
            area: item["regionName"],
            shopStatus: item["platformStatus"],
            authType: item["authType"],
            authTime: item["createdTime"],
            expire_time: item["expire_time"],
            isVipActive: item["isVipActive"],
            shop_cbsc: item["shop_cbsc"],
          })
        })
        _this.setState({
          tableData: tableData,
        })

      } else {
        _this.setState({
          tableData: [],
        })
        message.info("请求失败，请重试！")
      }
    });

  }



  render() {
    const { tableData, isModalOpen_addShop, spinning } = this.state;

    //判断是不是手机屏幕
    var isPhone = CommonUtils.isPhoneClient()


    var columns = [
      {
        title: '店铺ID',
        dataIndex: 'shopId',
        key: 'shopId',
        // render: (text) => <a>{text}</a>,
        responsive: ['lg'],//响应式配置。未设置则始终可见
      },
      {
        title: '店铺名称',
        dataIndex: 'shopName',
        key: 'shopName',
      },
      {
        title: '地区',
        dataIndex: 'area',
        key: 'area',
        responsive: ['lg'],//响应式配置。未设置则始终可见
      },
      {
        title: '店铺类型',
        dataIndex: 'shop_cbsc',
        key: 'shop_cbsc',
        responsive: ['lg'],//响应式配置。未设置则始终可见
        render: (text,record) => <>{text == "LOCAL" ? "本土" : "跨境"}</>
      },
      {
        title: '授权状态',
        dataIndex: 'authType',
        key: 'authType',
        render: (text) => <>已授权</>
      },
      {
        title: '授权时间',
        dataIndex: 'authTime',
        key: 'authTime',
        ellipsis: true,
        responsive: ['lg'],//响应式配置。未设置则始终可见
      },
      {
        title: '是否订购',
        dataIndex: 'isVipActive',
        key: 'isVipActive',
        render: (text) => <a>{text ? "已订购" : "未订购"}</a>,
        responsive: ['lg'],//响应式配置。未设置则始终可见
      },
      {
        title: '操作',
        key: 'action',
        width: "78px",//只有文字才有用
        render: (_, record) => {
          // console.log(record)
          return <Space size="middle">
            <a className="colCardActionBtn" onClick={() => this.deleteShopFun(record.shopId)}>取消授权</a>
          </Space>
        },
      },
    ];

    const searchItems = [
      {
        "title": "授权状态",
        "key": "authTypeParams",
        "type": "select",
        "options": [{ label: "全部", value: "" },
        { label: "已授权", value: "已授权" },
        { label: "失效", value: "失效" },
        ]
      },
      {
        "title": "店铺站点",
        "key": "region",
        "type": "select",
        "options": [{ label: "全部", value: "" },
        { label: "英国", value: "GB" },
        { label: "美国", value: "US" },
        { label: "越南", value: "VN" },
        { label: "泰国", value: "TH" },
        { label: "印尼", value: "ID" },
        { label: "马来西亚", value: "MY" },
        { label: "新加坡", value: "SG" },
        { label: "菲律宾", value: "PH" },
        ]
      },
      {
        "title": "关键词",
        "key": "keyword",
        "type": "input",
        "placeholder": "店铺名称/店铺ID",
      },
    ]

    return (
      <React.Fragment>
        <div style={{ margin: "10px 0px" }}>
          <Button type="primary" style={{ margin: "0px 10px" }} onClick={() => this.setStateKeyValue("isModalOpen_addShop", true)}>添加授权店铺</Button>
        </div>
        <SearchItem searchFun={this.searchFun} searchItems={searchItems} />
        <Spin tip="查询中..." spinning={spinning}>
          <Table columns={columns} dataSource={tableData} size={isPhone ? "small" : "middle"}
            pagination={{
              position: ["bottomCenter"], showSizeChanger: true,
              pageSizeOptions: [10, 20, 50, 100],
              showTotal: (total) => `共 ${total} 条`,
            }}
          />
        </Spin>

        <Modal title="添加授权店铺-tiktok" open={isModalOpen_addShop} footer={null} onCancel={() => this.setStateKeyValue("isModalOpen_addShop", false)}>
          <Form
            name="添加授权店铺-tiktok"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            style={{
              maxWidth: 600,
            }}
            onFinish={this.addAuthShopFun}
            autoComplete="off"
          >
            <Form.Item
              label="店铺名称"
              name="shop_name"
              rules={[
                {
                  required: true,
                  message: '请输入店铺名称!',
                },
              ]}
            >
              <Input placeholder="如：店铺1-tiktok" />
            </Form.Item>

            <Form.Item
              label="Client ID"
              name="shopId"
              rules={[
                {
                  required: true,
                  message: '请输入Client ID!',
                },
              ]}
            >
              <Input type="number" />
            </Form.Item>
            <Form.Item
              label="API 密钥"
              name="accessToken"
              rules={[
                {
                  required: true,
                  message: '请输入API 密钥!',
                },
              ]}
            >
              {/* <Input.Password /> */}
              <Input />
            </Form.Item>
            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              <Button type="primary" htmlType="submit">
                添加授权
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ShopAuthTiktok;