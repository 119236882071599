import React from "react";
import { addNews } from "../http/api";
import CommonUtils from '../http/Common';
import {
  Descriptions, Radio, Button, Checkbox, Form, Flex, Input, Col, Row, Modal, Table, Space,
  message, Popconfirm, Badge, Typography, Popover, Tooltip, Image, Spin, Tag, Upload,
} from 'antd';
import { QuestionCircleOutlined, QrcodeOutlined } from '@ant-design/icons';
import { NavLink, withRouter } from 'react-router-dom';
import SearchItem from "./SearchItem.js";
import dayjs from 'dayjs';
const { Paragraph, Text } = Typography;

class CreateNewsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paramsParams: {},//分页查询的参数缓存
      spinning: false,//是否为加载中状态
      showModel_: false,
      // contentTextList: [{ type: "text", value: "1" }, { type: "img", value: "https://baodanerp.cn/img/pro/home1.png" }, { type: "text", value: "3" },],//内容数组 
      contentTextList: [{ type: "text", value: "点击图标可编辑" }],//内容数组 
    };
  }

  componentDidMount() {
    let _this = this;
    CommonUtils.goToTop()//回到顶部

    
  }

  setStateKeyValue = (key, value) => {
    this.setState({
      [key]: value
    })
  }

  //跳转到另一个路由
  jumpToNewHash = (hashPath) => {
    this.props.history.push(hashPath);//跳转到新的路由
  }

  //内容添加一行
  addLine = () => {
    var contentTextList = this.state.contentTextList
    contentTextList.push({ type: "text", value: "" })
    this.setState({
      contentTextList: contentTextList
    })
  }

  //内容变化
  changeContentTextFun = (index, value) => {
    var contentTextList = this.state.contentTextList
    console.log("index,value ==>", index, value)
    contentTextList[index] = { type: "text", value: value }
    this.setState({
      contentTextList: contentTextList
    })
  }

  getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  selectFileChangeFun = async ({ fileList: newFileList }) => {
    var imgPreview = await this.getBase64(newFileList[0].originFileObj);
    if (newFileList.length && newFileList[0].size > 1024 * 1024) {
      var size = newFileList[0].size / (1024 * 1024)
      Modal.success({
        title: '提示',
        content: '您的图片大小为：' + size.toFixed(2) + 'M。发票图片最大不能超过1M！',
      })
    } else {
      var contentTextList = this.state.contentTextList
      contentTextList.push({ type: "img", value: imgPreview,file:newFileList[0].originFileObj })
      this.setState({
        contentTextList: contentTextList
      })
    }
  }

  //保存
  saveFun = () => {
    let _this = this;
    const { title, originalUrl, tag, contentTextList } = this.state;
    // console.log("title,originalUrl,tag,contentTextList ==> ", title, originalUrl, tag, contentTextList)
    if(!title){
      message.warning("请填写标题！")
      return
    }
    if(!originalUrl){
      message.warning("请填写原文网址！")
      return
    }
    if(!tag){
      message.warning("请选择文章标签！")
      return
    }
    if(!contentTextList || contentTextList.length == 0){
      message.warning("请填写文章内容！")
      return
    }

    // 构建表单数据
    const formData = new FormData();
    formData.append('title', title); //参数
    formData.append('tag', tag || ""); //参数
    formData.append('originalUrl', originalUrl || ""); //参数

    var contentList = []
    contentTextList.map((item,index)=>{
      if(item.type == "img"){
        var imgName = 'contentImg'+index
        formData.append(imgName, item.file)
        //item.value = imgName
        contentList.push({ type: "img", value: imgName })
      }else{
        contentList.push(item)
      }
    })
    formData.append('content', JSON.stringify(contentList) || ""); //参数


    addNews(formData).then(res=>{

      if(res && res.data && res.data.success){
             let resData = res.data.data;

             message.success("请求成功！")
       }else{
           _this.setState({
              tableData:[],
            })
         }
    }).catch(function (error) {//请求失败时
    message.info("创建失败，请重试！")
  })

  }

  render() {
    const { spinning, showModel_, contentTextList, } = this.state;
    //判断是不是手机屏幕
    var isPhone = CommonUtils.isPhoneClient()


    return (
      <React.Fragment>
        <div style={{ textAlign: "right", marginBottom: "10px" }}>
          <Button type="primary" style={{ marginRight: "10px" }} onClick={this.saveFun}>保存</Button>
        </div>
        <Form autoComplete="off" >
          <Form.Item label="文章标题" name="title" rules={[{ required: false, },]}>
            <Input placeholder="请在文本框内输入文字标题" onChange={(e) => this.setStateKeyValue("title", e.target.value)} />
          </Form.Item>
          <Form.Item label="原文网址" name="originalUrl" rules={[{ required: false, },]}>
            <Input placeholder="请在文本框内输入原文url，没有填：无" onChange={(e) => this.setStateKeyValue("originalUrl", e.target.value)} />
          </Form.Item>

          <Form.Item label="文章标签" name="tag" rules={[{ required: false, },]}>
            <Radio.Group onChange={(e) => this.setStateKeyValue("tag", e.target.value)}>
              <Radio.Button value={1}>行业资讯</Radio.Button>
              <Radio.Button value={2}>运营技巧</Radio.Button>
              <Radio.Button value={3}>基础常识</Radio.Button>
              <Radio.Button value={4}>国家政策</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="文章正文" name="content" rules={[{ required: false, },]}>
            <div>
              <div>
                <Button onClick={this.addLine} style={{ marginRight: "10px" }}>添加一段</Button>
                <Upload accept="image/*"
                  maxCount={1}
                  showUploadList={false}
                  onChange={this.selectFileChangeFun}
                  beforeUpload={() => { return false; }}
                >
                  <Button >添加图片</Button>
                </Upload>
              </div>
              <div style={{ marginTop: "10px" }}>
                {
                  contentTextList.map((item, index) => {
                    if (item.type == "text") {
                      return <Paragraph className="newsDetailContent" editable={{ onChange: (value) => this.changeContentTextFun(index, value), }}  >
                        {item.value}
                      </Paragraph>
                    } else if (item.type == "img") {
                      return <div className="textAlignCenter" style={{ margin: "20px 0px" }}>
                        <Image src={item.value} preview={false} height={280}></Image>
                      </div>
                    }
                  })
                }
              </div>
            </div>
          </Form.Item>
        </Form>







        <Modal title="添加xpath" open={showModel_} footer={null} onCancel={() => this.setStateKeyValue("showModel_", false)}>
          <Form labelCol={{ span: 5, }}
            wrapperCol={{ span: 19, }}
            style={{ maxWidth: 600, }}
            onFinish={this.addAuthShopFun}
            autoComplete="off"
          >
            <Form.Item label="店铺名称" name="shop_name"
              rules={[{
                required: true,
                message: '请输入店铺名称!',
              },]} >
              <Input placeholder="如：店铺1-ozon" />
            </Form.Item>

            <Form.Item label="Client ID" name="shopId"
              rules={[{
                required: true,
                message: '请输入店铺名称!',
              },]} >
              <Input type="number" />
            </Form.Item>
            <Form.Item label="API 密钥" name="accessToken"
              rules={[{
                required: true,
                message: '请输入店铺名称!',
              },]} >
              {/* <Input.Password /> */}
              <Input />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 5, span: 16, }} >
              <Button type="primary" htmlType="submit">
                添加授权
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </React.Fragment>
    );
  }
}

export default withRouter(CreateNewsPage);